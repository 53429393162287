var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-text-field',{staticClass:"mb-3 pa-2",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"value":_vm.selection,"items":_vm.getDrivers,"item-key":"id","show-select":"","dense":""},on:{"item-selected":_vm.selectionChanged},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var header = ref.header;
return undefined}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"disabled":item.dispatched,"on-icon":"mdi-checkbox-marked-circle","off-icon":"mdi-power-off","color":"primary","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-1 mr-1",attrs:{"size":"32"}},[(item.avatar)?_c('img',{attrs:{"alt":"Avatar","src":item.avatar}}):_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(_vm._s(_vm.userInitials(item.username)))])],1),_c('span',{class:!item.enabled ? 'text-decoration-line-through red--text' : ''},[_vm._v(_vm._s(item.username))]),(!item.enabled)?_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v("mdi-account-cancel")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }