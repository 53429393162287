<template>
  <view-container :scroll-target="scrollTarget" :auto-scroll="false">
    <div
      style="position: absolute;
      z-index: 9;
      overflow-y: hidden;
      max-height: 47px;
      border-left: 1px solid #eee;
      border-right: 1px solid #eee"
      >
      <v-btn-toggle tile v-model="view" color="primary" borderless>
        <v-btn @click="updateQuery(0)">
          <v-icon small>mdi-package-variant</v-icon>
          Loading
        </v-btn>
        <v-btn @click="updateQuery(1)">
          <v-icon small>mdi-truck-delivery</v-icon>
          Dispatched
        </v-btn>
      </v-btn-toggle>
    </div>
    <dispatcher
      v-if="view === 0"
      @scrolled="(e) => (scrollTarget = e)"
    />
    <dispatched-orders
      v-if="view === 1"
      @scrolled="(e) => (scrollTarget = e)"
    />
  </view-container>
</template>

<script>
import Dispatcher from "@/views/admin/orders/Dispatcher";
import DispatchedOrders from "@/views/admin/orders/DispatchedOrders";
import ViewContainer from "@/common/layouts/ViewContainer";

export default {
  name: "OrdersManager",
  components: { ViewContainer, Dispatcher, DispatchedOrders },
  data() {
    return {
      scrollTarget: null,
      view: 0,
    };
  },
  mounted() {
    this.view = parseInt(this.$route.query?.view || 0);
    // const topics = this.$generateStoreTopics(this.getStoreIdParam);
    // this.subscribe(topics);
  },
  // beforeDestroy() {
  //   const topics = this.$generateStoreTopics(this.getStoreIdParam);
  //   this.unsubscribe(topics);
  // },
  methods: {
    updateQuery(view) {
      if(this.view !== view) {
        this.$$router.replace({ name: this.$route.name, query: { view }});
      }
    }
  },
};
</script>
