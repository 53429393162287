<template>
  <v-container class="pa-0" fluid>
    <v-card flat>
      <toolbar>
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width: 0"
          clearable
        ></v-text-field>
        <v-divider vertical />
        <v-btn text x-small color="primary" @click="expandAll()">
          <v-icon>mdi-chevron-down</v-icon>
          <span class="text--secondary">{{ $t("general.expandAll") }}</span>
        </v-btn>
        <v-btn text x-small color="primary" @click="collapseAll()">
          <v-icon>mdi-chevron-up</v-icon>
          <span class="text--secondary">{{ $t("general.collapseAll") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="pa-0" :style="getViewHeightStyle(104, 0)">
        <v-container
          ref="dispatchContainer"
          @scroll.self="onScroll"
          class="dispatch-table-container"
        >
          <app-drawer v-model="detailsDrawer" @closed="hideOrderDetails">
            <order-details
              v-model="selectedOrder"
              customer
              driver
              support
              history
              :audiance="selectedOrder ? 'Dispatcher' : undefined"
              :height-offset="48"
              :offset="60"
              scan-mode
              @order:dismiss="hideOrderDetails"
            />
          </app-drawer>

          <table
            ref="dispatchContent"
            class="dispatch-table"
            :style="getInitTableHeight"
          >
            <thead>
              <tr>
                <th class="active">
                  <v-toolbar dense flat color="grey lighten-5">
                    <v-toolbar-title class="body-1">
                      <v-icon class="mx-1" color="green">
                        mdi-truck-fast-outline
                      </v-icon>
                      <strong>DISPATCHED</strong>
                    </v-toolbar-title>
                    <v-card class="px-3 py-1 ma-2" flat color="secondary">
                      {{ getActiveCount }}
                    </v-card>
                  </v-toolbar>
                </th>
                <th class="map">
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title class="body-1">
                      <v-icon small class="mx-1" color="green lighten-1">
                        mdi-map-marker-distance
                      </v-icon>
                      <strong>MAP</strong>
                    </v-toolbar-title>
                  </v-toolbar>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="active" @dragover.prevent @dragenter.prevent>
                  <div class="d-flex flex-wrap">
                    <template v-for="(order, i) in getActive">
                      <dispatch-card
                        v-if="foundInSearch(order)"
                        :key="i"
                        :order="order"
                        :driver="{ id: order.driverId, name: order.driverName }"
                        :selected="selectedOrder && selectedOrder.id === order.id"
                        :collapsed="order.collapsed"
                        back-icon="mdi-skip-backward"
                        @click:details="showOrderDetails(order)"
                        @click:back="callbackDispatch(order)"
                        @click:cancel="setStatus(order, 'CANCELLED')"
                      />
                    </template>

                    <v-alert v-if="hiddenItems" small color="yellow" class="ma-1 pa-1">
                      <v-icon>mdi-information-outline</v-icon>
                      <span class="body-2">Hidden orders by search</span>
                    </v-alert>

                    <!-- @click:reject="setStatus(order.id, 'CANCELLED')" -->
                    <!-- @click:next="setStatus(order.id, 'PREPARING')" -->
                  </div>
                </td>
                <td class="map">
                  <markers-map
                    :markers="getMarkers"
                    :geofence="getAosCoordinates"
                    style="height: 100%"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toolbar from "@/common/components/Toolbar";
import DispatchCard from "@/views/admin/orders/DispatchCard";
import {
  STATUS_LOADING,
  STATUS_DISPATCHED,
  STATUS_NEAR,
  STATUS_DELIVERY,
  STATUS_DELIVERED,
  STATUS_RETURN,
} from "@/store/actions/type.orders";
import AppDrawer from "@/common/components/AppDrawer.vue";
import OrderDetails from "@/views/orders/OrderDetails";
import MarkersMap from "@/common/components/MarkersMap";
import _ from "lodash";
import moment from "moment";
import mixin from "@/views/orders/orders.mixin";

export default {
  name: "DispatchedOrders",
  components: {
    Toolbar,
    DispatchCard,
    AppDrawer,
    OrderDetails,
    MarkersMap,
  },
  mixins: [mixin],
  data() {
    return {
      selectedDriverUuid: null,
      dragedOrder: null,
      loading: {
        activeOrders: false,
      },
      collapsedItems: [],
      search: null,
      detailsDrawer: false,
      selectedOrder: null,
    };
  },
  mounted() {
    this.setViewData({ title: this.$t("admin.route.dispatchedOrders") });
    this.loading.activeOrders = true;
    this.loadActiveOrders(this.getStoreIdParam)
      .then(() => {
        this.loading.activeOrders = false;
      })
      .catch((response) => console.log(response));

    // 4: drivers
    this.loadStoreUsersByType({
      storeId: this.getStoreIdParam,
      type: "DRIVER",
    });

    this.loadStore(this.getStoreIdParam);
  },
  computed: {
    ...mapGetters("orders", [
      "getActiveOrders",
      "getActiveOrdersByStatus",
      "getActiveOrdersByDriver",
      "getSelectedOrder",
    ]),
    ...mapGetters("ui", ["getLanguage"]),
    ...mapGetters("users", ["getUsersByProp"]),
    ...mapGetters("stores", ["getStoreById"]),
    ...mapGetters("auth", ["getCurrentUser"]),

    getActive() {
      const orders = this.getActiveOrdersByStatus([
        STATUS_DISPATCHED,
        STATUS_DELIVERY,
        STATUS_NEAR,
        STATUS_DELIVERED,
        STATUS_RETURN,
      ]);
      return orders;
    },
    getDispatched() {
      return this.getActiveOrdersByStatus(STATUS_DISPATCHED);
    },
    getNear() {
      return this.getActiveOrdersByStatus(STATUS_NEAR);
    },
    getDelivery() {
      return this.getActiveOrdersByStatus(STATUS_DELIVERY);
    },
    getDelivered() {
      return this.getActiveOrdersByStatus(STATUS_DELIVERED);
    },
    getActiveCount() {
      return (
        this.getDispatchedCount + this.getNearCount + this.getDeliveredCount
      );
    },
    getDispatchedCount() {
      return this.notEmptyArray(this.getDispatched)
        ? this.getDispatched.length
        : 0;
    },
    getDeliveredCount() {
      return this.notEmptyArray(this.getDelivered)
        ? this.getDelivered.length
        : 0;
    },
    getNearCount() {
      return this.notEmptyArray(this.getNear) ? this.getNear.length : 0;
    },
    isLoaded() {
      return this.loading.every((e) => e);
    },
    getInitTableHeight() {
      return this.getViewHeightStyle(104);
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getAosCoordinates() {
      const store = this.getStoreById(this.getStoreIdParam);
      return store && store.aosGeom && store.aosGeom.geometry
        ? store.aosGeom.geometry.coordinates
        : {};
    },
    getMarkers() {
      const locations = this.getActive.map((m) => {
        const orderAddress = m.customerAddresses.find(
          (f) => f.id === m.addressId
        );
        return {
          latitude: orderAddress.latitude,
          longitude: orderAddress.longitude,
          icon: "mdi-home-map-marker",
          color: "white",
          class: this.getStatusTypeColor(m.activeTrackStatus) + " pin",
          target: true,
          status: m.activeTrackStatus,
          selected: this.selectedOrder && this.selectedOrder.id === m.id,
          click: () => {},
        };
      });

      const drivers = _.uniqBy(this.getActive, "driverId").map((m) => ({
        latitude: m.latitude,
        longitude: m.longitude,
        icon: "mdi-moped",
        // icon: "mdi-truck-fast-outline",
        color: "black",
        class: "amber circle",
        status: moment(m.locationUpdated).fromNow(true),
        selected:
          this.selectedOrder && this.selectedOrder.driverId === m.driverId,
        click: () => {},
      }));

      return [
        {
          icon: "mdi-headset",
          color: "#195567",
          class: "secondary pin2",
          status: "MAKZANI",
          latitude: 12.75896,
          longitude: 44.89213,
          click: () => {},
        },
        ...locations,
        ...drivers,
      ];
    },
    hiddenItems() {
      return this.notEmptyArray(this.getActive) && this.getActive.some((f) => !this.foundInSearch(f));
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("orders", [
      "loadActiveOrders",
      "updateOrderStatus",
      "setSelectedOrder",
      "clearSelectedOrder",
      "collapseAll",
      "expandAll",
    ]),
    ...mapActions("users", [
      "loadStoreUsersByType",
      "loadDispatchedDrivers",
      "setDriverStatus",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog", "showFeedback", "setViewData"]),
    ...mapActions("stores", ["loadStore"]),

    foundInSearch(order) {
      return (
        !this.search ||
        (
          order.statusName +
          order.customerName +
          order.customerPhone +
          order.driverName +
          order.driverPhone
        )
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
      );
    },
    getActiveOrderById(id) {
      return this.getActiveOrders.filter((f) => f.id === id);
    },
    getTrackId(orderId, status) {},
    toggleCollapse(id) {
      if (!this.collapsedItems.includes(id)) {
        this.collapsedItems.push(id);
      } else {
        this.collapsedItems = this.collapsedItems.filter((f) => f !== id);
      }
    },
    getLoadingByDriver(driverId) {
      return this.notEmptyArray(this.getLoading)
        ? this.getLoading.filter((o) => o.driverId === driverId)
        : [];
    },

    showOrderDetails(order) {
      this.selectedOrder = order;
      this.detailsDrawer = true;
    },
    hideOrderDetails() {
      this.detailsDrawer = false;
      this.selectedOrder = null;
    },
    showCustomerInfo(customer) {},
    showDriverInfo(driver) {},

    callbackDispatch(order) {
      const orders = this.getActive.filter((f) => f.driverId === order.driverId && order.activeTrackStatus === 'DISPATCHED');
      if(!this.notEmptyArray(orders)) {
        return;
      }

      if(orders.length === 1) {
        this.setStatus(order, 'LOADING')
      } else {
        const dialog = {
          title: "Callback Dispatched",
          text: `Do you want call back all orders in DISPATCHED status to LOADING status, or only this one back to PREPARING?`,
          actions: [
            {
              text: "Only This",
              click: () => {
                  const params = {
                  orderUuid: order.uuid,
                  driverUuid: order.driverUuid,
                  status: 'PREPARING',
                  storeId: this.getStoreIdParam,
                };

                this.updateOrderStatus(params);
                this.closeDialog();
              },
              color: "success",
            },
            {
              text: "ALL",
                click: () => {
                  const params = [];
                  for(let i in orders) {
                    params.push({
                      orderUuid: orders[i].uuid,
                      driverUuid: orders[i].driverUuid,
                      status: 'LOADING',
                      storeId: this.getStoreIdParam,
                    });
                  }

                this.updateOrderStatus(params);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "Cancel",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },

    setStatus(order, status) {
      const dialog = {
        title: "Change Status",
        text: `Are you sure you want to change the status of this order to ${status}?`,
        actions: [
          {
            text: "Yes",
            click: () => {
              const params = {
                orderUuid: order.uuid,
                driverUuid: order.driverUuid,
                status,
                storeId: this.getStoreIdParam,
              };

              this.updateOrderStatus(params);
              this.closeDialog();
            },
            color: "red",
          },
          {
            text: "No",
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
    setLoading(orderId) {
      if (this.selectedDriverUuid) {
        this.updateOrderStatus({
          orderId,
          driverUuid: this.selectedDriverUuid,
          storeId: this.getStoreIdParam,
          status: STATUS_LOADING,
        });
      } else {
        this.showFeedback({
          show: true,
          title: "errors.error",
          text: "messages.selectDriver",
          timeout: 3000,
          color: "red lighten-2",
          // light: true,
        });
      }
    },
    onScroll(e) {
      this.$emit("scrolled", {
        value: e.target.scrollTop,
        target: this.$refs.dispatchContent,
        container: this.$refs.dispatchContainer,
      });
    },
    dispatchDelivery(driver) {
      // this.setDriverStatus({ uuid: driver.uuid, status: STATUS_DISPATCHED });
      const orders = this.getLoadingByDriver(driver.id);
      for (let i in orders) {
        this.updateOrderStatus({
          orderUuid: orders[i].uuid,
          driverUuid,
          STATUS_DISPATCHED,
          storeId: this.getStoreIdParam,
        });
      }
    },
    isLastDriverOrder(driverUuid, orderId) {
      if (!driverUuid) {
        return false;
      }

      const orders = this.getActiveOrdersByDriver(driverUuid);
      return (
        orders &&
        !orders.some(
          (f) =>
            [
              STATUS_LOADING,
              STATUS_DISPATCHED,
              STATUS_DELIVERY,
              STATUS_NEAR,
              STATUS_DELIVERED,
              STATUS_RETURN,
            ].includes(f.status) && f.orderId !== orderId
        )
      );
    },
  },
};
</script>

<style scoped>
.dispatch-table-container {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
}

.dispatch-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border: 1px solid #eee;
}
.dispatch-table th {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.dispatch-table tbody > tr:not(:last-child) > td:first-child {
  border-bottom: 2px dotted #ddd;
}

.dispatch-table tbody > tr:not(:last-child) > td:not(:first-child) {
  border-bottom: 2px dotted #fff;
}

.dispatch-table .active {
  background-color: #fafafa;
  width: 220px !important;
}

.dispatch-table .map {
  border-left: 2px dotted #ddd;
  background-color: #fafafa;
}

.dispatch-table td.map {
  padding: 0;
}

.dispatch-table td {
  padding: 10px;
  vertical-align: top;
}
</style>
