<template>
  <v-card
    width="200"
    class="ma-1"
    hover
    :tile="selected"
    :style="`${selected ? 'z-index: 9; border: 1px solid ' + getThemeColor('primary') + ';' : ''}`"
  >
    <v-sheet
      :class="`d-flex card-title ${getCardColor}`"
      :style="`height: 35px; ${fade ? 'filter: grayscale(0.9); opacity: 0.5;' : ''}`"
    >
      <img height="28" :src="getAvatar(order.customerUuid)" style="margin-left: 2px" />
      <div
        :class="`ma-1 ${$display.getStatusTextColor(
          order.activeTrackStatus
        )}--text`"
      >
        {{ order.customerName }}
      </div>
      <v-spacer />
      <!-- v-if="order.activeTrackStatus === 'PREPARING'" -->
      <v-btn
        small
        depressed
        color="white"
        icon
        @click="collapsed = !collapsed"
        style="margin-top: 3px"
      >
        <v-icon v-if="collapsed">mdi-chevron-down</v-icon>
        <v-icon v-if="!collapsed">mdi-chevron-up</v-icon>
      </v-btn>
    </v-sheet>

    <v-card-text class="pa-0" v-if="!collapsed" @click="$emit('click:details')">
      <v-list dense class="pa-0">
        <v-list-item v-if="driver" style="font-size: 10pt; padding: 0">
          <v-icon class="mx-1">mdi-moped</v-icon> {{ driver.name }}
          <v-spacer />
          <v-progress-circular rotate="-90" :value="getProgress" color="primary" background-color="secondary" size="37" width="3" class="ma-1">
            <div style="font-size: 6pt;">{{ getProgress }}%</div>
          </v-progress-circular>
        </v-list-item>
        <v-divider v-if="driver" />

        <v-list-item style="font-size: 10pt; padding: 0">
          <v-list-item-content class="d-flex align-baseline py-0">
            <table class="delivery-table">
              <tr style="background: #fff8af">
                <td>No.</td>
                <td style="text-align: right">
                  {{ order.id }}
                </td>
              </tr>
              <tr>
                <td>Price:</td>
                <td style="text-align: right">
                  {{
                    order.orderPrice.toLocaleString({
                      minimumFractionDigits: 2,
                    }) || "-"
                  }}
                </td>
              </tr>
              <tr>
                <td>Items:</td>
                <td style="text-align: right">
                  <v-chip
                    label
                    color="light-grey"
                    class="px-1"
                    style="max-height: 18px"
                    >{{ order.items.length }}</v-chip
                  >
                </td>
              </tr>
              <tr>
                <td>Time:</td>
                <td style="text-align: right">
                  <v-chip
                    small
                    label
                    dark
                    :color="
                      days > 0
                        ? 'gray lighten-1'
                        : hours === 0
                        ? 'green lighten-2'
                        : hours === 1
                        ? 'orange lighten-2'
                        : 'red'
                    "
                    class="px-1"
                    style="max-height: 18px"
                  >
                    <span class="mr-1">{{ getOrderTime }}</span>
                    <v-icon small> mdi-clock-time-eight </v-icon>
                  </v-chip>
                </td>
              </tr>
            </table>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider /> -->
      </v-list>
    </v-card-text>
    <v-card-actions
      v-if="
        !collapsed && !['CANCELLED', 'DELIVERY', 'NEAR', 'DELIVERED'].includes(order.activeTrackStatus)
      "
      style="height: 20px"
      class="pa-0"
    >
      <v-btn
        v-if="['PREPARING', 'LOADING', 'DISPATCHED'].includes(order.activeTrackStatus)"
        small
        color="orange"
        icon
        dark
        depressed
        tile
        @click="$emit('click:back')"
      >
        <v-icon>{{ backIcon || "mdi-skip-previous" }}</v-icon>
      </v-btn>
      <v-spacer
        v-if="['PREPARING', 'DISPATCHED'].includes(order.activeTrackStatus)"
      />
      <v-btn
        v-if="!order.scanned && ['WAITING', 'PREPARING'].includes(order.activeTrackStatus)"
        small
        icon
        color="red lighten-2"
        @click="
          order.activeTrackStatus === 'WAITING'
            ? $emit('click:reject')
            : $emit('click:cancel')
        "
      >
        <v-icon>mdi-stop</v-icon>
      </v-btn>
      <v-spacer
        v-if="(order.scanned && order.activeTrackStatus === 'PREPARING') || order.activeTrackStatus === 'WAITING'"
      />
      <v-btn
        v-if="(order.scanned && order.activeTrackStatus === 'PREPARING') || order.activeTrackStatus === 'WAITING'"
        small
        color="green"
        icon
        dark
        depressed
        tile
        @click="$emit('click:next')"
      >
        <v-icon>mdi-skip-next</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";
import mixin from "@/views/orders/orders.mixin";
import moment from "moment";

export default {
  name: "DispatchCard",
  mixins: [mixin],
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    driver: Object,
    collapsed: Boolean,
    selected: Boolean,
    fade: Boolean,
    backIcon: String,
  },
  computed: {
    getProgress() {
      const s = this.order.activeTrackStatus;
      return s === "DISPATCHED" ? 25 : s === "DELIVERY" ? 50 : s === "NEAR" ? 75 : s === "DELIVERED" ? 100 : 0;
    },
    enabledForward() {
      return (
        this.order.activeTrackStatus === "DISPATCHED" ||
        this.order.activeTrackStatus === "DELIVERY" ||
        this.order.activeTrackStatus === "NEAR" ||
        this.order.activeTrackStatus === "DELIVERED"
      );
    },
    getOrderTime() {
      return moment(this.order.time).fromNow(true);
    },
    hours() {
      return moment().diff(this.order.time, "hours");
    },
    days() {
      return moment().diff(this.order.time, "days");
    },
    getCardColor() {
      const status = this.order.activeTrackStatus;
      return status === 'PREPARING' && this.order.scanned ? 'success' : this.$display.getStatusColor(status);
    },
  },
  methods: {
    printOrder() {},
  },
};
</script>

<style scoped>
.card-title {
  font-weight: 400;
  padding: 3px !important;
  height: 30px;
}

.delivery-table tr {
  background: #eee;
}

.delivery-table td {
  padding-right: 2px;
  padding-left: 2px;
}
</style>
