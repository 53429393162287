<template>
  <div
    v-if="toggledDrawerState"
    style="
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 99;
      top: 0;
      left: 0;
    "
  >
    <v-navigation-drawer
      app
      v-model="toggledDrawerState"
      :right="!getLocale.rtl"
      temporary
      permanent
      overlay-opacity="0"
      fixed
      width="430"
    >
      <v-btn
        tile
        v-if="toggledDrawerState"
        depressed
        style="position: absolute; left: 0px; z-index: 99; height: 48px; border-right: 1px solid #d1d1d1 !important;" 
        color="red lighten-1"
        dark
        class="px-3"
        @click="closeDrawer"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-container class="pa-0">
        <slot></slot>
      </v-container>
    </v-navigation-drawer>

    <div
      v-if="toggledDrawerState"
      style="
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.1;
      "
      @click="temporary ? closeDrawer : undefined"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AppDrawer",
  model: {
    prop: "drawer",
    event: "input",
  },
  props: {
    drawer: Boolean,
  },
  // watch: {
  //   drawer: {
  //     handler: function (n) {
  //       this.temporary = n;
  //     },
  //   },
  // },
  data() {
    return {
      temporary: false,
    };
  },
  // computed: {
  //   getDrawer: {
  //     get() {
  //       return this.drawer;
  //     },
  //     set(value) {
  //       this.drawer = value;
  //       this.$emit("update:drawer", value);
  //     },
  //   },
  // },
  computed: {
    toggledDrawerState: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.$emit("update:drawer", value);
      },
    },
  },
  methods: {
    closeDrawer() {
      this.temporary = false;
      this.$emit("closed");
    },
  },
};
</script>
