<template>
  <v-card width="100%" flat color="transparent">
    <v-card
      flat
      width="300"
      height="47"
      :style="`border-radius: 5px 5px 0 0; border: 1px solid #d0f3c5; border-bottom: 0; background-size: 28px 28px; background: ${
        selected ? '#d7feba' : 'white'
      }`"
    >
      <v-card-actions class="py-0 pl-1 pr-2">
        <v-btn
          v-if="!isActive"
          @click="$emit('select')"
          depressed
          small
          fab
          color="transparent"
        >
          <v-icon color="primary" v-if="selected">mdi-radiobox-marked</v-icon>
          <v-icon color="primary" v-if="!selected">mdi-radiobox-blank</v-icon>
        </v-btn>
        <v-list-item class="ma-0 pa-0" @click="$emit('click:driver')">
          <v-list-item-avatar color="grey darken-3" class="my-0 mx-1">
            <v-img :src="getUserAvatar" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>{{ driver.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-chip label small color="white" class="pr-4">
          <span class="title">{{ ordersLoad }}</span>
          <v-icon class="ml-1" small> mdi-home-group </v-icon>
        </v-chip>
        <v-btn
          @click="$emit('click:dispach')"
          depressed
          small
          color="primary"
          class="ml-2"
        >
          <v-icon> mdi-play </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      flat
      width="100%"
      min-height="30"
      class="pa-1"
      :style="`border-radius: 0 5px 5px 5px; border: 1px solid #d0f3c5; background: ${
        selected
          ? 'repeating-linear-gradient(45deg, #fff, #fff 5px, #d7feba 5px, #d7feba 10px);'
          : 'white'
      }`"
      @drop="$emit('drop')"
      @dragover.prevent
      @dragenter.prevent
    >
      <slot></slot>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "DeliveryCard",
  props: {
    driver: {
      type: Object,
      default() {
        return {};
      },
    },
    ordersLoad: {
      type: Number,
      default() {
        return 0;
      },
    },
    collapsed: Boolean,
    loading: Boolean,
    selected: Boolean,
  },
  computed: {
    getUserAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.driver.activeTrackUserUuid}`;
    },
    isActive() {
      return (
        this.driver.statusName === "DISPATCHED" || this.driver.statusName === "RETURNING"
      );
    },
  },
};
</script>
